import React, { Component } from 'react';
export default  class Resume extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="resume">

         
        <div className="row work">
            <div className="twelve columns header-col" style={{marginBottom: "5vh"}}>
               <h1><span>Work Experiences</span></h1>
            </div>

            <div className="twelve columns main-col">
              {
                resumeData.work && resumeData.work.map((item) => {
                  return(
                    <div className="row item">
                       <div className="twelve columns">
                         <div className="row">
                          <div className="columns one title-column">
                            <img className="logo" src={item.logo} alt="" />
                          </div>
                          <div className="columns eleven">
                            <h3>{item.CompanyName}</h3>
                            <p className="info">
                              {item.specialization}
                              <span>&bull;</span>
                              <em className="date">{item.MonthOfStarting} {item.YearOfStarting}</em> ~ <em className="date">{item.MonthOfLeaving} {item.YearOfLeaving}</em>
                            </p>
                          </div>
                          </div>
                          <ul>
                            {item.Achievements.map((achievement) => {
                              return (
                                <li key={achievement} dangerouslySetInnerHTML={{__html: achievement}} />
                              )
                            })}
                          </ul>
                       </div>

                    </div>

                  )
                })
              }
            </div> 
         </div>
         <div className="row education">

          <div className="three columns header-col">
            <h1><span>Education</span></h1>
          </div>

          <div className="nine columns main-col">
            {
              resumeData.education && resumeData.education.map((item)=>{
                return(
                  <div className="row item">
                    <div className="twelve columns">
                      <div className="row">
                        <div className="columns one title-column">
                          <img className="logo" src={item.logo} alt="" />
                        </div>
                        <div className="columns eleven">
                          <h3>{item.UniversityName}</h3>
                          <p className="info">
                          {item.specialization}
                          <span>&bull;</span>
                          <em className="date">{item.MonthOfStarting} {item.YearOfStarting}</em> ~ <em className="date">{item.MonthOfPassing} {item.YearOfPassing}</em>
                          </p>
                          <ul>
                            {item.Achievements.map((achievement) => {
                              return (
                                <li key={achievement}>
                                  {achievement}
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>

        <div className="row education">

          <div className="three columns header-col">
            <h1><span>Projects</span></h1>
          </div>

          <div className="nine columns main-col">
            {
              resumeData.projects && resumeData.projects.map((item)=>{
                return(
                  <div className="row item">
                    <div className="twelve columns">
                        <h3 dangerouslySetInnerHTML={{__html: item.UniversityName}}/>
                        <p className="info">
                        {item.specialization}
                        <span>&bull;</span>
                        <em className="date">{item.YearOfStarting}</em>
                        </p>
                        <ul>
                          {item.Achievements.map((achievement) => {
                            return (
                              <li key={achievement} dangerouslySetInnerHTML={{__html: achievement}} />
                            )
                          })}
                        </ul>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>

        <div className="row skill">

          <div className="three columns header-col">
            <h1><span>Achievements</span></h1>
          </div>

          <div className="nine columns main-col">
            {
              resumeData.awards && resumeData.awards.map((item)=>{
                return(
                  <div className="row item">
                    <div className="twelve columns">
                        <h3>{item.UniversityName}</h3>
                        <p className="info">
                        {item.specialization}
                        <span>&bull;</span>
                        <em className="date">{item.YearOfStarting}</em>
                        </p>
                        <ul>
                          {item.Achievements.map((achievement) => {
                            return (
                              <li key={achievement} dangerouslySetInnerHTML={{__html: achievement}} />
                            )
                          })}
                        </ul>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>

         {/* <div className="row skill">

            <div className="three columns header-col">
               <h1><span>Skills</span></h1>
            </div>

            <div className="nine columns main-col">

               <p>
               {resumeData.skillsDescription}
               </p>

   				<div className="bars">

   				   <ul className="skills">
                {
                  resumeData.skills && resumeData.skills.map((item) => {
                    return(
                      <li>
                      <span className={`bar-expand ${item.skillname.toLowerCase()}`}>
                      </span><em>{item.skillname}</em>
                      </li>
                    )
                  })
                }

   					</ul>

   				</div>

   			</div>

         </div> */}

      </section>
    );
  }
}