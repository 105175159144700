import React, { Component } from 'react';
export default class Porfolio extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="portfolio">
      <div className="row">
        <div className="twelve columns collapsed">
          <h1>Skills</h1>
          <div className="row">
            <div className="five columns">
              <h1 id="section-header"><span>Languages</span></h1>
              <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf" style={{marginBottom: "30px"}}>
                {
                  resumeData.languages && resumeData.languages.map((item)=>{
                    return(
                      <div className="columns">
                        <div dangerouslySetInnerHTML={{__html: item.image}}/>
                        <h6>{item.name}</h6>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className="five columns">
            <h1 id="section-header"><span>Data Science & Engineering</span></h1>
              <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf" style={{marginBottom: "30px"}}>
                {
                  resumeData.datascience && resumeData.datascience.map((item)=>{
                    return(
                      <div className="columns">
                        <div dangerouslySetInnerHTML={{__html: item.image}}/>
                        <h6>{item.name}</h6>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="five columns">
              <h1 id="section-header"><span>Web Development & Databases</span></h1>
              <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
                {
                  resumeData.webdev && resumeData.webdev.map((item)=>{
                    return(
                      <div className="columns">
                        <div dangerouslySetInnerHTML={{__html: item.image}}/>
                        <h6>{item.name}</h6>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className="five columns">
              <h1 id="section-header"><span>Miscellaneous</span></h1>
              <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
                {
                  resumeData.misc && resumeData.misc.map((item)=>{
                    return(
                      <div className="columns">
                        <div dangerouslySetInnerHTML={{__html: item.image}}/>
                        <h6>{item.name}</h6>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
  </section>
        );
  }
}